import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import FormProperties from './FormProperties'
import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import Stepper from '../../../components/aciacehighway/Stepper'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngButton },
  form: { CngAddForm }
} = components

function AddPage(props) {
  const { history, location, onSetLoading, showNotification } = props

  const onSuccessCallback = useRef((e) =>
    history.push(`${pathMap.NBCI_US_INVOICE}/edit/` + e.id)
  )

  function handleSaveAndContinue() {
    onSuccessCallback.current = (e) => {
      history.push({
        pathname: `${pathMap.NBCI_US_INVOICE}/edit/` + e.id,
        state: { step: 1, invoiceData: e }
      })
    }
  }

  return (
    <Card>
      <Stepper
        steps={['Provide details', 'Add invoice lines & summary invoice']}
        activeStep={0}
      />
      <Box padding={2}>
        <CngAddForm
          fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
          history={history}
          showNotification={showNotification}
          renderBodySection={(labelMap, shouldHideMap) => (
            <FormProperties.Fields
              showNotification={showNotification}
              shouldHideMap={shouldHideMap}
              onSetLoading={onSetLoading}
            />
          )}
          formikProps={{
            ...FormProperties.formikProps,
            initialValues: {
              ...FormProperties.formikProps.initialValues,
              ...location.state,
              id: '',
              invHeaderstatus: '1005',
              invoiceNumber: ''
            }
          }}
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          create={{
            url: NbciUsInvoiceApiUrls.POST,
            onSuccess: (e) => {
              const { errorMessages } = e

              if (!_.isEmpty(errorMessages)) {
                errorMessages.forEach((message) =>
                  showNotification('error', message)
                )
              } else {
                showNotification('success', 'Invoice created successfully.')
                onSuccessCallback.current(e)
              }
            }
          }}
          renderButtonSection={(loading) => (
            <CustomButtonSection
              history={history}
              loading={loading}
              onSaveAndContinue={handleSaveAndContinue}
              onSetLoading={onSetLoading}
            />
          )}
        />
      </Box>
    </Card>
  )
}

function CustomButtonSection(props) {
  const { history, loading, onSaveAndContinue, onSetLoading } = props
  const [alertDialog, setAlertDialog] = useState(false)
  const {
    formState: { isSubmitting }
  } = useFormContext()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={isSubmitting}
            onClick={() => setAlertDialog(true)}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                disabled={isSubmitting}
                onClick={onSaveAndContinue}
                size='medium'
                type='submit'
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => history.push(pathMap.NBCI_US_INVOICE_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(AddPage)
