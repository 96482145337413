import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Please enter alpha numeric values other than <,>,*,~'

  return Yup.object({
    reference: Yup.string().nullable(),
    referenceNumber: Yup.string()
      .nullable()
      .matches(regexAlphaNumeric, errMsgAlphaNumeric)
      .max(50, 'Max length allowed is 50')
  })
}

export default makeValidationSchema
