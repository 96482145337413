import React, { useEffect } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import CiFormFormProperties from './CiFormFormProperties'
import CargoSummaryFormProperties from './CargoSummaryFormProperties'
import useCustomYupValidationResolver from '../../../components/aciacehighway/hooks/useCustomYupValidationResolver'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import CargoSummarySection from './CargoSummarySection'
import { FormProvider, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import Grid from '@material-ui/core/Grid'

function CiFormSection(props) {

  const { watch, setValue } = useFormContext()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const ciForm = watch('ciForm')
  const cargoSummary = watch('ciForm.0.nbciusinvoiceCargoSummary')

  function makeTranslatedTextsObject() {
    const cargoSummary = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.NBCIUSInvoiceCargoSummary.TITLE
    )

    return { cargoSummary }
  }

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CollapsibleSection
            defaultExpanded={!_.isEmpty(ciForm)}
          onExpandedChange={(expanded) => {
            if (expanded && !ciForm) {
              setValue('ciForm', CiFormFormProperties.formikProps.initialValues)
            }
          }}
            title='Additional Information'
          >
          {ciForm && (
            <CiFormFormProperties.Fields />
          )}
          </CollapsibleSection>
        </Grid>
      <Grid item xs={12}>
          <CollapsibleSection
          defaultExpanded={!_.isEmpty(cargoSummary)}
            title={translatedTextsObject.cargoSummary}
          >
            <CargoSummarySection cargoSummary={cargoSummary} />
          </CollapsibleSection>
        </Grid>
    </Grid>
  )
}

export default CiFormSection
