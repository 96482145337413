import React, { useEffect, useRef, useState } from 'react'
import {
  components,
  DataFlattener,
  useServices,
  useTranslation
} from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../constants/locale/key/NbciUsInvoice'
import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import FormProperties from './FormProperties'
import Grid from '@material-ui/core/Grid'
import pathMap from '../../../paths/pathMap'
import PrintFlagDialog from '../../../views/nbci/usinvoice/PrintFlagDialog'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

const {
  button: { CngButton },
  form: { CngEditForm }
} = components

function EditPage(props) {
  const {
    history,
    invoiceData,
    onDiscard,
    onProceedNextStep,
    onSetInvoiceData,
    onSetLoading,
    showNotification,
    id
  } = props

  const onSuccessCallback = useRef(() => {})
  const [printFlagDialog, setPrintFlagDialog] = useState({
    open: false,
    manifest: null
  })

  return (
    <>
      <CngEditForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        showNotification={showNotification}
        renderBodySection={(labelMap, shouldHideMap) => (
          <FormProperties.Fields
            showNotification={showNotification}
            shouldHideMap={shouldHideMap}
            onSetLoading={onSetLoading}
          />
        )}
        formikProps={FormProperties.formikProps}
        toClientDataFormat={FormProperties.toClientDataFormat}
        toServerDataFormat={FormProperties.toServerDataFormat}
        fetch={{
          url: NbciUsInvoiceApiUrls.GET,
          onPreSuccess: (serverData) => {
            onSetInvoiceData(serverData)
          }
        }}
        update={{
          url: NbciUsInvoiceApiUrls.PUT,
          onPostSubmitSuccess: (e) => {
            onSetInvoiceData(e)
            onSuccessCallback.current()
          }
        }}
        id={id}
        renderButtonSection={(_, loading) => (
          <CustomButtonSection
            data={invoiceData}
            history={history}
            loading={loading}
            onDiscard={onDiscard}
            onSaveAndContinue={() => {
              onSuccessCallback.current = () => {
                onProceedNextStep()
                onSetLoading(false)
              }
            }}
            onSetLoading={onSetLoading}
            onSetPrintFlagDialog={() =>
              setPrintFlagDialog({ open: true, manifest: invoiceData })
            }
            onSkip={onProceedNextStep}
            showNotification={showNotification}
          />
        )}
      />
      <PrintFlagDialog
        openDialog={printFlagDialog}
        onClose={() => setPrintFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
      />
    </>
  )
}

export default EditPage

function CustomButtonSection(props) {
  const {
    data,
    history,
    loading,
    onDiscard,
    onSaveAndContinue,
    onSetLoading,
    onSetPrintFlagDialog,
    onSkip,
    showNotification
  } = props

  const [isSubmittingInvoice, setIsSubmittingInvoice] = useState(false)
  const {
    formState: { isSubmitting },
    handleSubmit
  } = useFormContext()
  const { securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    onSetLoading(loading)
  }, [loading])

  function makeTranslatedTextsObject() {
    const submitButton = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.SUBMIT_BUTTON
    )

    return { submitButton }
  }

  async function handleSubmitInvoice(formData) {
    setIsSubmittingInvoice(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      formData.fileForUserId = fileForUserDetails.fileForUserId;
      formData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      formData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        securedSendRequest.execute(
          'POST',
          NbciUsInvoiceApiUrls.SUBMIT,
          DataFlattener.unflatten({ ...data, ...formData }),
          (response) => {
            var data = response.data
            var errorMessages = data.errorMessages
            if (
              response.status == 200 &&
              data != null &&
              errorMessages == null
            ) {
              showNotification('success', 'Invoice submitted successfully.')
              history.push(pathMap.NBCI_US_INVOICE_LIST_VIEW)
            } else {
              showNotification('error', errorMessages)
            }
          },
          (error) => console.error(error)
        )
        resolve()
      }, 500)
    }).finally(() => setIsSubmittingInvoice(false))
  }

  return (
    <>
      <Grid container justify='space-between' spacing={3}>
        <Grid item xs='auto'>
          <CngButton
            color='secondary'
            disabled={loading || isSubmitting}
            onClick={onDiscard}
            size='medium'
          >
            Discard
          </CngButton>
        </Grid>
        <Grid item xs='auto'>
          <Grid container spacing={2}>
            <Grid item xs='auto'>
              <CngButton
                color='secondary'
                disabled={loading || isSubmitting}
                onClick={onSkip}
                size='medium'
              >
                Skip
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                disabled={loading || isSubmitting}
                onClick={onSetPrintFlagDialog}
                size='medium'
                shouldShowProgress={isSubmittingInvoice}
              >
                Print Invoice Level Forms
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                disabled={loading || isSubmitting}
                onClick={handleSubmit(handleSubmitInvoice)}
                size='medium'
                shouldShowProgress={isSubmittingInvoice}
              >
                {translatedTextsObject.submitButton}
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading || isSubmitting}
                type='submit'
                size='medium'
              >
                Save as draft
              </CngButton>
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                color='primary'
                disabled={loading}
                onClick={onSaveAndContinue}
                type='submit'
                size='medium'
                shouldShowProgress={loading}
              >
                Save &amp; continue
              </CngButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
