import { Box, Card } from '@material-ui/core'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useServices } from 'cng-web-lib'
import pathMap from '../../../paths/pathMap'
import NbciUsInvoiceApiUrls from '../../../apiUrls/NbciUsInvoiceApiUrls'
import withFormState from '../../../components/aciacehighway/hocs/withFormState'
import Stepper from '../../../components/aciacehighway/Stepper'
import InvoiceEditForm from './InvoiceEditForm'
import InvoiceDetailsAndSummaryInvoiceSection from './InvoiceDetailsAndSummaryInvoiceSection'
import ResponseLogAndHistorySection from './ResponseLogAndHistorySection'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import { FileForUserGetPartyId, FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

function EditPage(props) {
  const { history, onSetLoading, showNotification, location } = props
  const { state } = location
  const [step, setStep] = useState(state?.step ? state.step : 0)
  const [alertDialog, setAlertDialog] = useState(false)
  const { id } = useParams()
  const [invoiceData, setInvoiceData] = useState(state?.invoiceData)
  const { securedSendRequest } = useServices()

  const [printFlagDialog, setPrintFlagDialog] = useState({
    open: false,
    manifest: null
  })

  function renderContent() {
    switch (step) {
      case 0:
        return (
          <InvoiceEditForm
            history={history}
            id={id}
            invoiceData={invoiceData}
            onDiscard={() => setAlertDialog(true)}
            onProceedNextStep={() => setStep((step) => step + 1)}
            onSetInvoiceData={setInvoiceData}
            onSetLoading={onSetLoading}
            showNotification={showNotification}
          />
        )
      case 1:
        return (
          <InvoiceDetailsAndSummaryInvoiceSection
            invoiceData={invoiceData}
            onBack={() => setStep((step) => step - 1)}
            onDiscard={() => setAlertDialog(true)}
            onSetInvoiceData={setInvoiceData}
            onSetLoading={onSetLoading}
            onSkip={() => setStep((step) => step + 1)}
            onPrintLineItem={() =>
              setPrintFlagDialog({ open: true, manifest: invoiceData })
            }
            onSubmit={handleSubmit}
            showNotification={showNotification}
          />
        )
      case 2:
        return (
          <ResponseLogAndHistorySection
            id={id}
            onBack={() => setStep((step) => step - 1)}
            onDiscard={() => setAlertDialog(true)}
            onSubmit={handleSubmit}
          />
        )
    }
  }

  function handleSubmit() {
    onSetLoading(true)

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      invoiceData.fileForUserId = fileForUserDetails.fileForUserId;
      invoiceData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      invoiceData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      NbciUsInvoiceApiUrls.SUBMIT,
      invoiceData,
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages

        if (response.status == 200 && data != null && errorMessages == null) {
          showNotification('success', 'Submit successfully')
          history.push(pathMap.NBCI_US_INVOICE_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      (error) => console.error(error),
      () => onSetLoading(false)
    )
  }

  return (
    <>
      <Card>
        <Stepper
          steps={[
            'Provide details',
            'Add invoice lines & summary invoice',
            'View logs & history'
          ]}
          activeStep={step}
          onSetActiveStep={(step) => setStep(step)}
        />
        <Box padding={2}>{renderContent()}</Box>
      </Card>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={alertDialog}
        onClose={() => setAlertDialog(false)}
        onCancel={() => setAlertDialog(false)}
        onConfirm={() => history.push(pathMap.NBCI_US_INVOICE_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and can't be restored. Are you
        sure about this?
      </AlertDialog>
    </>
  )
}

export default withFormState(EditPage)
